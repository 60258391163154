<template>
  <div class="home">
    <div class="filter">
      <span :class="[lang]">{{ $t("Status") }}</span>
      <a-select
        class="select"
        :class="[lang]"
        placeholder="Please select status"
        style="width: 360px"
        size="large"
        v-model="status"
        @change="handleChange"
      >
        <a-select-option :class="[lang]" value="">
          {{ $t("All") }}</a-select-option
        >
        <a-select-option :class="[lang]" value="0">
          {{ $t("PendingConnection") }}</a-select-option
        >
        <a-select-option :class="[lang]" value="1">
          {{ $t("ConnectionRequestSent") }}
        </a-select-option>
        <a-select-option :class="[lang]" value="2">
          {{ $t("ConnectionApproved") }}
        </a-select-option>
        <a-select-option :class="[lang]" value="4">
          {{ $t("ResponseReceived") }}
        </a-select-option>
        <a-select-option :class="[lang]" value="5">
          {{ $t("LeadContactInformationObtained") }}
        </a-select-option>
      </a-select>
    </div>
    <div class="wrap-table table-content-wrapper">
      <a-table
        :scroll="{
          x: 1120,
          y: tableScrollY,
        }"
        :class="{ arTable: lang == 'AR' ? true : false }"
        size="middle"
        :columns="columns"
        :rowKey="(record, index) => index"
        :data-source="tableData"
        :pagination="false"
        :loading="loading"
        :customRow="
          (record) => {
            return {
              on: {
                click: () => goDetail(record),
              },
            };
          }
        "
        :row-class-name="
          (_record, index) => (index % 2 !== 1 ? 'table-bg' : null)
        "
      >
        <template slot="name" slot-scope="text, record" class="arial">
          <div class="wrap-name">
            <div class="wrap-img">
              <img
                v-if="record.head_img"
                :src="record.head_img"
                :class="{ rtl: lang == 'AR' ? true : false }"
              />
              <img
                v-else
                src="@/assets/img/avat.png"
                :class="{ rtl: lang == 'AR' ? true : false }"
              />
              <!-- <div class="new"></div> -->
            </div>
            <span class="skip" @click="skip(record)">{{ record.name }}</span>
          </div>
        </template>

        <template slot="action" slot-scope="text, record" class="arial">
          <span
            v-if="!showPageFooter"
            :class="[lang]"
            @click="upgreadHandler"
            class="statusBtn"
            >{{ $t("AutomaticConnections") }}</span
          >
          <!-- <span
            v-if="record.status == 0"
            class="statusText"
            style="color: #666"
          >
            --
          </span> -->
          <span
            v-if="record.status == 1"
            class="statusText"
            style="color: #666"
          >
            {{ $t("ConnectionRequestSent") }}
          </span>
          <span
            v-if="record.status == 2"
            class="statusText"
            style="color: #666"
          >
            {{ $t("ConnectionApproved") }}
          </span>
          <span
            v-if="record.status == 4"
            class="statusText"
            style="color: #666"
          >
            {{ $t("ResponseReceived") }}
          </span>
          <span
            v-if="record.status == 5"
            class="statusText"
            style="color: #666"
          >
            {{ $t("LeadContactInformationObtained") }}
          </span>
        </template>
      </a-table>
      <div v-if="showPageFooter">
        <app-footer
          :current="page"
          :pageSize="pageSize"
          :total="total"
          @onPageChange="onPageChange"
          @onPageSizeChange="onPageSizeChange"
        ></app-footer>
      </div>
    </div>
    <div class="planGuide" v-if="!showPageFooter">
      <div class="left">
        <div class="tit">{{ $t("viewComplteList") }}</div>
        <div class="piclist">
          <ul>
            <li :style="{ zIndex: 1, left: 0 }">
              <img src="@/assets/img/avat.png" />
            </li>
            <li :style="{ zIndex: 1, left: '-15px' }">
              <img src="@/assets/img/avat.png" />
            </li>
            <li :style="{ zIndex: 1, left: '-30px' }">
              <img src="@/assets/img/avat.png" />
            </li>
            <li :style="{ zIndex: 1, left: '-45px' }">
              <img src="@/assets/img/avat.png" />
            </li>
          </ul>
          <span class="dotted">···</span>
        </div>
        <p :class="[lang]">
          <!-- <span class="green">230 customers</span>
          are waiting for you -->
          {{ $t("CustomersAwaiting").replace("xxx", "") }}
        </p>
      </div>
      <div class="right">
        <div class="off">{{ $t("SaveNow") }}</div>
        <div class="tit"></div>
        <ul>
          <li>
            <div class="time">{{ days }}</div>
            <div class="flag">{{ $t("day") }}</div>
          </li>
          <li>
            <div class="time">{{ hours }}</div>
            <div class="flag">{{ $t("Hrs") }}</div>
          </li>
          <li>
            <div class="time">{{ minutes }}</div>
            <div class="flag">{{ $t("Mins") }}</div>
            <div class="split">:</div>
          </li>
          <li>
            <div class="time">{{ seconds }}</div>
            <div class="flag">{{ $t("Secs") }}</div>
            <div class="split">:</div>
          </li>
        </ul>
        <div class="btn" @click="upgreadHandler">{{ $t("updateBtn") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseComponent from "@/components/base.component";

export default {
  data() {
    return {
      loading: false,
      tableData: [],
      showPageFooter: true,
      status: "",
      computedTable: true,
      isCountDown: true,
    };
  },
  beforeRouteEnter(to, from, next) {
    if (
      from.fullPath == "/hr/recruiter/home" ||
      from.fullPath == "/hr/recruiter/campaign"
    ) {
      next((vm) => {
        vm.status = "";
        vm.page = 1;
        vm.pageSize = 10;
      });
    } else {
      next();
    }
  },
  activated() {
    this.getData();
    // let user = localStorage.getItem("admin.user")
    //   ? JSON.parse(localStorage.getItem("admin.user"))
    //   : {};
    // user.create_date = "2024-10-31 22:29:20";
    //this.countDown(user.create_date);
  },
  created() {},
  i18n: require("./i18n"),
  mixins: [BaseComponent],
  computed: {
    columns: {
      get() {
        let that = this;
        return [
          {
            title: that.$t("fullName"),
            width: 200,
            dataIndex: "name",
            align: "center",
            // fixed: "left",
            scopedSlots: {
              customRender: "name",
            },
          },
          {
            title: that.$t("Position"),
            width: 160,
            dataIndex: "position",
            align: "center",
            ellipsis: true,
            customRender: function (text) {
              return text ? text : "--";
            },
          },

          {
            title: that.$t("Company"),
            dataIndex: "company",
            align: "center",
            ellipsis: true,
            customRender: function (text) {
              return text ? text : "--";
            },
          },
          {
            title: that.$t("Location"),
            dataIndex: "localtion",
            align: "center",
            customRender: function (text) {
              return text ? text : "--";
            },
          },
          {
            title: that.$t("NumberofInteractions"),
            dataIndex: "conversation_num",
            align: "center",
            customRender: function (text) {
              return text ? text : "--";
            },
          },
          {
            title: that.$t("ResumeInformation"),
            dataIndex: "resume",
            align: "center",
            customRender: function (text) {
              return text == 1 ? that.$t('yes') : that.$t('no');
            },
          },
          {
            title: that.$t("ContactInformation"),
            dataIndex: "contact_information",
            align: "center",
            customRender: function (text) {
              return text ? text : "--";
            },
          },

          {
            title: that.$t("Status"),
            align: "center",
            // fixed: "right",
            scopedSlots: {
              customRender: "action",
            },
          },
        ];
      },
    },
  },
  beforeDestroy() {
    if (this.countDownTimer) {
      clearInterval(this.countDownTimer);
    }
  },
  methods: {
    upgreadHandler() {
      this.$router.push({
        path: "/setting/userSetting/plan",
        query: {
          upgrade_free: 1,
          campaign_type: 2,
          originUrl: this.$route.fullPath,
        },
      });
    },
    skip(record) {
      let { customer_id, conversation_num } = record;
      if (conversation_num == 0) {
        return;
      }
      this.$router.push({
        path: "/hr/recruiter/dialogue",
        query: {
          campaign_id: this.$route.query.campaign_id,
          customer_id: customer_id,
        },
      });
    },
    handleChange(val) {
      this.page = 1;
      this.getData(val);
    },
    createCampaign() {
      this.$router.push({ path: "/hr/recruiter/createCampaign" });
    },
    getData() {
      let { campaign_id } = this.$route.query;

      if (!campaign_id) {
        return;
      }
      this.loading = true;
      this.$axios(
        "/recruiter/campaign_detail",
        {
          page: this.page,
          page_size: this.pageSize,
          campaign_id: parseInt(campaign_id),
          customer_status: this.status ? parseInt(this.status) : null,
        },
        "post"
      ).then((res) => {
        this.loading = false;
        if (res.code == 200) {
          let { results, total, page_size, linkedin_subscription } =
            res.data || {};
          this.showPageFooter = linkedin_subscription == 1 ? false : true;
          this.isCountDown = linkedin_subscription == 1 ? true : false;
          if (this.isCountDown) {
            this.loading = true;
            // let { start_date, end_date } = this.user.free_info;
            // let start = new Date(start_date).getTime();
            // let end = new Date(end_date).getTime();
            // let days = Math.ceil((end - start) / (24 * 60 * 60 * 1000));
            this.getServiceTime()
              .then(() => {
                this.countDown(this.user.create_date, 10);
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          }
          this.tableData = results;
          this.total = total;
          this.pageCount = Math.ceil(total / page_size);
          this.resize();
        }
      });
    },
    goDetail(record) {
      // let { campaign_id, customer_id } = record;
      // this.$router.push({
      //   path: "/hr/recruiter/dialogue",
      //   query: { campaign_id, customer_id },
      // });
      console.log(record);
    },
    onChangeRecord(record) {
      console.log(record, arguments);
    },
    handleMenuClick(val) {
      console.log(val, arguments);
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  margin-top: 50px;
  padding: 20px 30px;
  ul,
  li {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .wrap-table {
    padding: 20px 20px 12px 20px;
    background: #fff;
    border-radius: 4px;
    margin-top: 20px;
  }
  .wrap-name {
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 10px;
    span {
      display: flex;
      align-items: center;
    }
    .wrap-img {
      position: relative;

      img {
        width: 39px;
        height: 39px;
        border-radius: 50%;
        margin-right: 12px;
      }
      .rtl {
        margin-right: 0px;
        margin-left: 12px;
      }
      .new {
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background: #ff0c0c;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  /deep/.ant-table-row-hover {
    background: rgba(238, 248, 247, 1) !important;
  }
  /deep/.ant-btn {
    background: #0c6158;
    color: #fff;
  }
  .filter {
    .select {
      margin-left: 20px;
    }
  }
  .planGuide {
    width: 100%;
    height: 217px;
    background: url(../../../assets/img/detail_bg.png) no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
    // margin-left: -30px;
    //margin-right: -30px;
    .left {
      width: 58%;
      padding-top: 49px;
      text-align: center;
      .tit {
        font-weight: bold;
        font-size: 30px;
        color: #ffffff;
        margin-bottom: 9px;
      }
      .piclist {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 42px;
        .dotted {
          color: #fff;
          font-size: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: -38px;
        }
        ul li {
          display: inline-block;
          position: relative;
          left: -30px;
          img {
            width: 42px;
            height: 42px;
            background: #a2a2a2;
            border: 1px solid #ffffff;
            border-radius: 50%;
          }
        }
      }
      p {
        padding-top: 15px;
        font-weight: 400;
        font-size: 18px;
        color: #ffdfe1;
        margin-bottom: 0;
        .green {
          color: #fff;
          font-weight: bold;
        }
      }
    }
    .right {
      width: 42%;
      text-align: center;
      color: #fff;
      .off {
        margin-top: 30px;
        font-weight: 900;
        font-size: 20px;
      }
      .tit {
        font-weight: 400;
        font-size: 14px;
      }
      ul {
        display: flex;
        margin-top: 12px;
        margin-left: -28px;
        align-items: center;
        justify-content: center;
        li {
          display: inline-block;
          width: 48px;
          height: 48px;
          margin-left: 28px;
          border-radius: 7px;
          background: #fff;
          text-align: center;
          position: relative;
          .time {
            width: 48px;
            margin-top: 5px;
            font-weight: bold;
            font-size: 24px;
            color: #ff7700;
            height: 27px;
            line-height: 27px;
          }
          .flag {
            width: 100%;
            font-weight: bold;
            font-size: 8px;
            color: #5d3c10;
            line-height: 12px;
            text-align: center;
          }
          .split {
            height: 48px;
            line-height: 48px;
            color: #fff;
            font-weight: bold;
            font-size: 36px;
            position: absolute;
            left: -20px;
            top: 0;
          }
        }
      }
      .btn {
        display: inline-block;
        min-width: 226px;
        height: 42px;
        text-align: center;
        line-height: 42px;
        font-weight: 900;
        font-size: 18px;
        padding: 0 20px;
        color: #ffffff;
        background: linear-gradient(92deg, #ffac00 0%, #f85760 100%);
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16),
          inset 0px 3px 6px 1px rgba(255, 255, 255, 0.45);
        border-radius: 32px 32px 32px 32px;
        border: 1px solid #ffffff;
        margin: 0 auto;
        margin-top: 15px;
        cursor: pointer;
      }
    }
  }
  .statusBtn {
    display: inline-block;
    height: 26px;
    line-height: 26px;
    padding: 0 9px;
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;
    background: #0e756a;
    border-radius: 3px 3px 3px 3px;
    cursor: pointer;
  }
  .statusText {
    display: inline-block;
    padding: 0 12px;
  }
}
.skip {
  cursor: pointer;
}
</style>
